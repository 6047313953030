import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { Space } from 'components';
import { BullResultCard } from 'components/BullSearch';
import { actions, Store } from 'rdx';
import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bull } from 'types';
import utils from 'utils';

const BullList: FC = () => {

  const { scope, items } = useSelector((store: Store) => store);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSingleSelection = (v: boolean, bull: Bull | number) => {
    const index = typeof bull === 'number'
      ? bull
      : items.bulls.findIndex(b => b.CRVcode === bull.CRVcode);
    dispatch(actions.items[v ? 'selectBull' : 'unselectBull'](index));
    if (!v) {
      dispatch(actions.items.removeAllSelected({
        type: 'breed',
        key: scope.breed || ''
      }));
    }
  };

  const onSelectAll = useCallback((v: boolean) => {
    items.scopedBulls.forEach(index => handleSingleSelection(v, index));
    dispatch(actions.items[v ? 'pushAllSelected' : 'removeAllSelected']({
      type: 'breed',
      key: scope.breed || ''
    }));
  }, [ handleSingleSelection ]);

  const currentBreed = items.breeds.find(b => b.name.toLowerCase() === scope.breed?.toLowerCase());

  let stamps: any[] = [];

  for (let i = 0; i < items.bulls.length; i++) {
    if (items.bulls[i].breed == currentBreed?.name) {
      for (let j = 0; j < items.bulls[i].stamps.length; j++) {
        if (stamps.indexOf(items.bulls[i].stamps[j].toUpperCase()) == -1) {
          stamps.push(items.bulls[i].stamps[j].toUpperCase());
        }
      }
    }
  }

  const location = useLocation();

  useEffect(() => {
    if (scope.stamps != null && scope.stamps.length > 0) {
      dispatch(actions.scope.changeStamps(new Array<string>()));
      dispatch(actions.scope.changeNationality('all'));
    }
  }, [ location ]);

  stamps = stamps.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  const nationalities: string[] = [ 'Nacional', 'Importado' ];

  return (
    <Stack
      { ...utils.stack.flex.col('flex-start', 'center') }
      sx={ utils.sx.fw }
      spacing={ 2 }
    >
      <Stack direction='row' spacing={ 2 } width='100%'>
        {
          currentBreed &&
          <FormControl
            style={{ width: '40%' }}
            sx={{ maxWidth: '570px' }}
          >
            <InputLabel id='rating-label'> Avaliação </InputLabel>
            <Select
              labelId='rating-label'
              defaultValue='all'
              value={ scope.rating ?? 'all' }
              label='Avaliação'
              onChange={(e) => dispatch(actions.scope.changeRating(e.target.value))}
            >
              {
                currentBreed.filters.length === 0 &&
                <MenuItem disabled value=''> Nenhum avaliação encontrada </MenuItem>
              }
              {
                currentBreed.filters.length > 0 &&
                <MenuItem value='all'> Todas as avaliações </MenuItem>
              }
              {
                currentBreed.filters.map(p => (
                  <MenuItem key={p.name} value={p.name}>{ p.name }</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        }
        {
          currentBreed &&
          <FormControl
            style={{ width: '40%' }}
            sx={{ maxWidth: '570px' }}
          >
            <InputLabel id='stamps-label'> Selos </InputLabel>
            <Select
              labelId='stamps-label'
              label='Selo'
              value={scope.stamps}
              renderValue={(selected) => selected.join(', ')}
              onChange={(e) => dispatch(actions.scope.changeStamps(e.target.value as string))}
              multiple={true}
            >
              {
                stamps.length === 0 &&
                <MenuItem disabled value=''> Nenhum selo encontrado </MenuItem>
              }
              {
                stamps.map(s => (
                  <MenuItem key={s} value={s}>
                    <Checkbox checked={scope.stamps.includes(s)} />
                    <ListItemText primary={s} />
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        }
        {
          (currentBreed && (currentBreed.name.toLowerCase() == 'aberdeen angus' || currentBreed.name.toLowerCase() == 'red angus')) &&
          <FormControl
            style={{ width: '20%' }}>
            <InputLabel id='nationality-label'> Origem </InputLabel>
            <Select
              labelId='nationality-label'
              value={scope.nationality ?? 'all'}
              defaultValue='all'
              label='Origem'
              onChange={(e) => dispatch(actions.scope.changeNationality(e.target.value))}
              sx={{ maxWidth: '570px' }}

            >
              {
                nationalities.length > 0 &&
                <MenuItem value='all'> Todas </MenuItem>
              }
              {
                nationalities.map(n => (
                  <MenuItem key={ n } value={ n }>{ n }</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        }
      </Stack>
      <Stack
        { ...utils.stack.flex.row('flex-start', 'center') }
        sx={ utils.sx.fw }
        spacing={ 1 }
      >
        <Checkbox
          checked={ utils.isAllSelected(items.allSelected, scope, 'breed') }
          sx={(theme) => ({ color: theme.palette.primary.main, padding: '0px' })}
          onChange={(_, v) => onSelectAll(v)}
        />
        <Typography> Selecionar todos </Typography>
      </Stack>
      <Space size={ 1 } key='breed-page-space-1' />
      <Stack
        { ...utils.stack.flex.col('flex-start', 'flex-start') }
        sx={{ width: '100%' }}
        spacing={ 2 }
      >
        {
          items.scopedBulls.map((n) => {
            const bull = items.bulls[n];
            return (
              <>
                {/* <p>{n}</p> */}
                <BullResultCard
                  bull={ bull }
                  key={ bull.name }
                  onClick={() => navigate(utils.string.printableToPathlike(bull.CRVcode.toUpperCase()))}
                  onChange={(v) => handleSingleSelection(v, bull)}
                />
              </>
            );
          })
        }
      </Stack>
    </Stack>
  );
};

export default BullList;