import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Segment } from 'types';

export type Scope = {
  segment: Segment,
  filterValue: string,
  breed: string | null,
  bull: string | null,
  rating: string | null,
  stamps: string[],
  nationality: string | null
}

const initialState: Scope = {
  segment: '',
  breed: null,
  filterValue: '',
  bull: null,
  rating: null,
  stamps: new Array<string>(),
  nationality: null
};

const segment = createSlice({
  name: 'scope',
  initialState,
  reducers: {
    changeSegment: (state, action: PayloadAction<Segment>) => {
      state.segment = action.payload;
    },
    changeFilterValue: (state, action: PayloadAction<string>) => {
      state.filterValue = action.payload;
    },
    changeBreed: (state, action: PayloadAction<string | null>) => {
      state.breed = action.payload;
    },
    changeBull_crv_code: (state, action: PayloadAction<string | null>) => {
      state.bull = action.payload;
    },
    changeRating: (state, action: PayloadAction<string | null>) => {
      state.rating = action.payload;
    },
    changeStamps: (state, action: PayloadAction<string | string[]>) => {
      state.stamps = action.payload as string[];
    },
    changeNationality: (state, action: PayloadAction<string | null>) => {
      state.nationality = action.payload;
    },
  }
});

export const scopeActions = segment.actions;
export default segment.reducer;