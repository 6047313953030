import { Box, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { Feedback, Footer, Header, Img, LocationTracker, MainLoading, Page } from 'components';
import { actions, Store } from 'rdx';
import { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { bulls } from 'services';
import { Breed, Bull, LocationTrackerItem, Segment } from 'types';
import utils from 'utils';

const normStr = utils.string.normalize;

const dictionary = {
  catalog: 'Catálogo',
  'bull-search': 'Busca de touros',
  'consultant-search': 'Busca de consultores'
};

const breedsHighligted = [
  'aberdeen angus',
  'gir leiteiro',
  'holandês preto e branco',
  'nelore mocho',
  'nelore',
  'brangus'
];

const toPaddedStr = (n: number) => n.toString().padStart(2, '0');

const MainLayout2: FC = () => {

  const dispatch = useDispatch();
  const { scope, feedback, items, modal } = useSelector((store: Store) => store);
  const lastLengthSelected = useRef(0);
  const isPositiveRef = useRef(false);

  const { pathname } = window.location;
  const gtMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    (async () => {
      if (items.selectedBulls.length !== lastLengthSelected.current) {
        if (feedback.bottomAlert.visible) {
          dispatch(actions.feedback.hideBottomAlert());
          await new Promise(f => setTimeout(f, 300));
        }
        const diff = items.selectedBulls.length - lastLengthSelected.current;
        isPositiveRef.current = diff > 0;
        const op = diff > 0 ? 'add' : 'rm';
        const elements = Math.abs(diff);
        const remaining = lastLengthSelected.current - elements;
        const text = op === 'add'
          ? `Você selecionou ${toPaddedStr(elements)} touro${elements > 1 ? 's' : ''} para gerar o catálogo personalizado`
          : [
            `Você retirou ${toPaddedStr(elements)} touro${elements > 1 ? 's' : ''} da lista`,
            ...(
              remaining === 0
                ? []
                : [ `Resta${remaining > 1 ? 'm' : ''} ${toPaddedStr(remaining)} touro${remaining > 1 ? 's' : ''}` ]
            )
          ];
        dispatch(actions.feedback.setBottomAlertText(text));
        dispatch(actions.feedback.showBottomAlert(dispatch));
        
        lastLengthSelected.current = items.selectedBulls.length;
      }
    })();
  }, [ items.selectedBulls ]);

  useEffect(() => {
    dispatch(actions.items.setMainLoading(true));
    bulls.getAll()
      .then((res) => {
        const { bulls, breedsProofs: breeds } = res.data;
        const sortedBulls = bulls.sort((a, b) =>
          normStr((a?.warName != '-' ? a?.warName : a.name) ?? '') < normStr((b?.warName != '-' ? b?.warName : b.name) ?? '')
            ? -1
            : normStr((a?.warName != '-' ? a?.warName : a.name) ?? '') > normStr((b?.warName != '-' ? b?.warName : b.name) ?? '') ? 1 : 0
        );
        dispatch(actions.items.setBulls(sortedBulls));
        dispatch(actions.items.setBreeds(breeds));
        const highlight = breeds.filter(
          b => breedsHighligted.includes(b.name.toLowerCase())
        ).map(b => breeds.findIndex(e => e.name === b.name));
        dispatch(actions.items.setHighlighBreeds(highlight));
        setTimeout(() => dispatch(actions.items.setMainLoading(false)), 500);
      })
      .catch(err => {
        console.log({ err });
        setTimeout(() => dispatch(actions.items.setMainLoading(false)), 500);
      });
  }, []);

  useEffect(() => {
    dispatch(actions.scope.changeRating('all'));
  }, [ scope.breed, scope.segment, scope.filterValue ]);

  useEffect(() => {
    const scopedBulls = items.bulls.reduce(
      (acc: number[], bull, index) =>
        (
          utils.segmentFromBullOrBreed(bull) === scope.segment &&
          (
            !scope.breed || // skip condition
            scope.breed.toLowerCase() === bull.breed.toLowerCase()
          ) &&
          (
            (scope.rating === 'all' || scope.rating === null) || // skip condition
            (
              bull.proof.length > 0 &&
              bull.proof.map(e => Object.entries(e)[0][0]).includes(scope.rating)
            )
          ) &&
          (
            (scope.stamps == null || scope.stamps.length == 0) ||
            (
              bull.stamps != null && bull.stamps.length > 0 &&
              bull.stamps.map(s => scope.stamps.includes(s.toUpperCase())).filter(s => s == true).length == scope.stamps.length
            )
          ) &&
          (
            (scope.nationality == null || scope.nationality == 'all' || (scope.breed != null && scope.breed.toLowerCase().indexOf('angus') == -1)) ||
            (
              !scope.nationality ||
              (bull.nationality != null && scope.nationality.toLowerCase() === bull.nationality.toLowerCase())
            )
          )
        )
          ? [ ...acc, index ]
          : acc,
      []
    );
    dispatch(actions.items.changeScopedBulls(scopedBulls));
  }, [ scope, items.bulls ]);
  
  let trackedLocation: LocationTrackerItem[] = [];

  const sections = (window.location.pathname ?? '').split('/');
  
  if (sections.includes('segment')) {
    const [ segment, breed, bullcode ] = sections.slice(2).filter(e => e !== 'bull-search');
    dispatch(actions.scope.changeSegment(
      (segment === '' ? '' : utils.segmentTranslator(segment, 'short')) as Segment
    ));
    dispatch(actions.scope.changeBreed(
      // eslint-disable-next-line no-extra-boolean-cast
      !!breed ? utils.string.pathlikeToPrintable(breed) : null
    ));
    const targetBull = items.bulls.find(b => utils.compareWithNullable(b.CRVcode, bullcode));
    dispatch(actions.scope.changeBull_crv_code(
      // eslint-disable-next-line no-extra-boolean-cast
      !!bullcode ? targetBull?.CRVcode || null : null
    ));
  } else {
    dispatch(actions.scope.changeBull_crv_code(null));
    dispatch(actions.scope.changeBreed(null));
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [ scope, pathname ]);

  if (scope.segment !== '') {
    const segmentTracking: LocationTrackerItem = {
      pathname: `/segment/${utils.segmentTranslator(scope.segment, 'pathlike')}`,
      printable: utils.segmentTranslator(scope.segment, 'printable'),
      segment: scope.segment
    };
    trackedLocation = [ segmentTracking ];
    if (scope.breed) {
      const breedTracking: LocationTrackerItem = {
        pathname: `/segment\
        /${utils.segmentTranslator(scope.segment, 'pathlike')}\
        /${utils.string.printableToPathlike(scope.breed)}`.replace(/(\s|\t)*/g, ''),
        printable: scope.breed,
        segment: scope.segment
      };
      trackedLocation = [ segmentTracking, breedTracking ];
      if (scope.bull) {
        const targetBull = items.bulls.find(b => b.CRVcode === scope.bull);
        const bullPrintableName = targetBull?.warName != '-' ? targetBull?.warName ?? '' : targetBull?.name ?? '';
        const bullTracking: LocationTrackerItem = {
          pathname: `/segment\
          /${utils.segmentTranslator(scope.segment, 'pathlike')}\
          /${utils.string.printableToPathlike(scope.breed)}\
          /${utils.string.printableToPathlike(bullPrintableName)}`
            .replace(/(\s|\t)*/g, ''),
          printable: bullPrintableName,
          segment: scope.segment
        };
        trackedLocation = [ segmentTracking, breedTracking, bullTracking ];
      }
    }
    if (sections.includes('catalog')) {
      const catalogTracking: LocationTrackerItem = {
        pathname: '/catalog',
        printable: 'Catálogo personalizado',
        segment: ''
      };
      trackedLocation = [ ...trackedLocation, catalogTracking ];
    }
    if (sections.includes('bull-search')) {
      const bullSearchTracking: LocationTrackerItem = {
        pathname: '/bull-search',
        printable: 'Busca de touros',
        segment: scope.segment
      };
      trackedLocation = [ ...trackedLocation, bullSearchTracking ];
    }
  } else {
    const paths = sections
      .filter(p => p !== '');
    
    if (paths.length > 0) {
      const tracking: LocationTrackerItem = {
        pathname: window.location.pathname,
        printable: utils.fromDictionary(dictionary, paths[0], utils.string.pathlikeToPrintable(paths[0])),
        segment: ''
      };
      trackedLocation = [ tracking ];
    }
  }
  
  useEffect(() => {
    dispatch(actions.feedback.setBottomAlertIconType(isPositiveRef.current ? 'ok' : 'error'));
  }, [ items.selectedBulls ]);

  return (
    <Page sx={{ paddingTop: gtMd ? '110px' : '110px' }}>
      <Header />
      <LocationTracker trackedLocation={ trackedLocation } />
      { items.mainLoading && <MainLoading /> }
      <Outlet />
      <Footer />
      <Feedback
        text={ feedback.bottomAlert.text }
        onClose={() => dispatch(actions.feedback.hideBottomAlert())}
      />
      {
        modal.secImgBull.open &&
        <Box sx={{
          ...utils.sx.flex.col('center', 'center'),
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0, reight: 0,
          background: '#333333cc',
          zIndex: 9
        }}>
          <Box sx={{ width: '90%', maxWidth: '450px', aspectRatio: '3/2', position: 'relative' }}>
            <Stack {...utils.stack.flex.col('flex-start', 'flex-start')}>
              <Img
                url={modal.secImgBull.url}
                fallbackImg='/imgs/default.png'
                sx={{ width: '100%' }}
              />
              {
                modal.secImgBull.subtitle &&
                modal.secImgBull.subtitle.trim() &&
                modal.secImgBull.subtitle.trim() !== '-' &&
                <Typography
                  sx={t => ({
                    width: '100%',
                    background: t.palette.primary.main,
                    color: 'white',
                    fontSize: '12px !important',
                    paddingX: '4px'
                  })}
                >
                  { modal.secImgBull.subtitle.trim() }
                </Typography>
              }
            </Stack>
            
            <Box
              sx={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                background: '#98002E',
                position: 'absolute',
                top: 0, right: 0,
                translate: '-50% 50%',
                border: '2px solid white',
                cursor: 'pointer',
                '::before': {
                  content: '""',
                  width: '2px',
                  height: '16px',
                  display: 'block',
                  background: 'white',
                  translate: '9px 2px',
                  rotate: '45deg'
                },
                '::after': {
                  content: '""',
                  width: '2px',
                  height: '16px',
                  display: 'block',
                  background: 'white',
                  translate: '9px -14px',
                  rotate: '-45deg'
                }
              }}
              onClick={() => dispatch(actions.modal.setSIBOpen(false))}
            />
          </Box>
        </Box>
      }
    </Page>
  );
};

export default MainLayout2;